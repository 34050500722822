// @file Helpers for dealing with emojis
import { __ } from '@@/bits/intl'
import { buildUrl } from '@@/bits/location'
import { EmojiVersion } from '@padlet/arvo'

const emojiCategories = ['people', 'nature', 'food', 'activity', 'travel', 'objects', 'symbols', 'flags']

const categoryTitles = {
  people: __('Smileys & People'),
  nature: __('Animals & Nature'),
  food: __('Food & Drink'),
  activity: __('Activity'),
  travel: __('Travel & Places'),
  objects: __('Objects'),
  symbols: __('Symbols'),
  flags: __('Flags'),
}

const categoryIcons = {
  people: 'smiley',
  nature: 'teddy',
  food: 'pizza',
  activity: 'ball',
  travel: 'car',
  objects: 'lightbulb',
  symbols: 'heart_outline',
  flags: 'flag_outline',
}

const categorySelectedIcons = {
  people: 'smiley_filled',
  nature: 'teddy_filled',
  food: 'pizza_filled',
  activity: 'ball_filled',
  travel: 'car_filled',
  objects: 'lightbulb_filled',
  symbols: 'heart_filled',
  flags: 'flag_filled',
}

// Joypixel emojis
const EMOJI_BASE_PATH = 'https://padlet.net/icons'
const EMOJI_VERSION = EmojiVersion.v8p0

/**
 * Composes a URL to an emoji.
 * @param type The type of the emoji (e.g. 'svg', 'png').
 * @param code A code that can be found in the emoji manifest.
 */
function emojiUrl(type: 'png' | 'svg', code: string): string {
  return buildUrl('https://padlet.net', `/icons/${EMOJI_VERSION}/${type}/${code}.${type}`)
}

const COLD_FACE_EMOJI = {
  iconSrc: emojiUrl('svg', '1f976'),
  iconAlt: __('Cold Face'),
}

const FACE_WITH_ROLLING_EYES_EMOJI = {
  iconSrc: emojiUrl('svg', '1f644'),
  iconAlt: __('Face With Rolling Eyes'),
}

const WAVING_HAND_EMOJI = {
  iconSrc: emojiUrl('svg', '1f44b'),
  iconAlt: __('Waving Hand'),
}

const OPEN_HANDS_ICON = {
  iconSrc: emojiUrl('svg', '1f450'),
  iconAlt: __('Open hands'),
}

const ROBOT_FACE_EMOJI = {
  iconSrc: emojiUrl('png', '1f916'),
}

const ROCKET_EMOJI = {
  iconSrc: emojiUrl('png', '1f680'),
}

const INCOMING_ENVELOPE_EMOJI = {
  iconSrc: emojiUrl('png', '1f4e8'),
}

const SINGLE_PIXEL_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

export {
  categoryIcons,
  categorySelectedIcons,
  categoryTitles,
  COLD_FACE_EMOJI,
  EMOJI_BASE_PATH,
  EMOJI_VERSION,
  emojiCategories,
  emojiUrl,
  FACE_WITH_ROLLING_EYES_EMOJI,
  INCOMING_ENVELOPE_EMOJI,
  OPEN_HANDS_ICON,
  ROBOT_FACE_EMOJI,
  ROCKET_EMOJI,
  SINGLE_PIXEL_PNG,
  WAVING_HAND_EMOJI,
}
